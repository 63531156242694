import React, { useEffect } from 'react'
import styles from './home.module.scss'
import DriveBanner1 from '../../assets/webp/drive_header.webp'
import DriveBannerMobile from '.././../assets/webp/drive_mobile_banner.webp'
import IphoneDesktop from '../../assets/webp/Iphone_desktop.webp'
import IphoneMobile from '../../assets/webp/Iphone_Mobile.webp'
import { ReactComponent as WindowsLogo } from '../../assets/svg/windows_logo.svg'
import { ReactComponent as MacLogo } from '../../assets/svg/mac_logo.svg'
import { ReactComponent as LinuxLogo } from '../../assets/svg/linux_logo.svg'
import { ReactComponent as AppStoreLogo } from '../../assets/svg/appStore_logo.svg'
import { ReactComponent as PlayStoreLogo } from '../../assets/svg/playStore_logo.svg'

import { ReactComponent as DriveFeature1 } from '../../assets/svg/driveFeature1.svg'
import { ReactComponent as DriveFeature2 } from '../../assets/svg/driveFeature2.svg'
import { ReactComponent as DriveFeature3 } from '../../assets/svg/driveFeature3.svg'
import { ReactComponent as DriveFeature4 } from '../../assets/svg/driveFeature4.svg'
import { ReactComponent as DriveFeature5 } from '../../assets/svg/driveFeature5.svg'
import { ReactComponent as DriveFeature6 } from '../../assets/svg/driveFeature6.svg'
import { ReactComponent as DriveFeature7 } from '../../assets/svg/driveFeature7.svg'
import { ReactComponent as DriveFeature8 } from '../../assets/svg/driveFeature8.svg'
import { ReactComponent as DriveFeature9 } from '../../assets/svg/driveFeature9.svg'
import { ReactComponent as DriveFeature10 } from '../../assets/svg/driveFeature10.svg'
import { ReactComponent as PurpleEclipse } from '../../assets/svg/purple_eclipse.svg'
import { ReactComponent as BlueEclipse } from '../../assets/svg/blue_eclipse.svg'

import Drive_Ilustration1_1 from '../../assets/webp/drive_illustration_0_1.webp'
import Drive_Ilustration1_2 from '../../assets/webp/drive_illustration_0_2.webp'
import Drive_Ilustration1_3 from '../../assets/webp/drive_illustration_0_3.webp'

import Drive_Ilustration1 from '../../assets/webp/drive_illustration1.webp'
import Drive_Ilustration2 from '../../assets/webp/drive_illustration2.webp'
import Drive_Ilustration3 from '../../assets/webp/drive_illustration3.webp'
import Drive_Ilustration5 from '../../assets/webp/drive_illustration5.webp'
import Drive_Ilustration4_2 from '../../assets/webp/drive_illustration4.2.webp'
import Drive_Ilustration4_2_Mobile from '../../assets/webp/drive_illustration_4.2_mobile.webp'
import Drive_Ilustration4_3 from '../../assets/webp/drive_illustration_4.3.webp'
import Drive_Ilustration4 from '../../assets/webp/recovery_img1.webp'
import SetsApart_desktop from '../../assets/webp/setsApart_desktop.webp'
import SetsApart_mobile from '../../assets/webp/setsApart_mobile.webp'
import Macbook_desktop from '../../assets/webp/macbook_desktop.webp'
import MacBook_mobile from '../../assets/webp/macbook_footer.webp'
import { DEVICE, PLATFORM, THEME } from '../../_shared/constants/constants'
import { Link } from 'react-router-dom';
import { useDownloadHook } from '../../_shared/hooks/download.hook';
import { useDeviceStore } from '../../stateManager/DeviceStore';
import { useOsStore } from '../../stateManager/OsStore';

import { lazily } from 'react-lazily';
const { FloaterDownload } = lazily(() => import('../../_shared/components/FloaterDownload/floaterDownload.component'));

function Home() {

    const { device } = useDeviceStore();
    const { os } = useOsStore();
    const { handleBuildDownload } = useDownloadHook();

    return (
        <div className={styles.homeContainer}>

            <FloaterDownload title='To protect your files' buttonText='Download' mode={`${THEME.DARK}`}/>

            <div className={styles.headerContainer}>
                <div className={styles.header}>
                    <div className={styles.heading}>
                        Keep your private life <span>private.</span>
                    </div>
                    <div className={styles.subHeading}>
                        Store, Search, Access, Sync, and Share Files with 100% Privacy
                    </div>

                </div>
                <div className={styles.headerImg}>
                    <img src={device !== DEVICE.MOBILE ? `${DriveBanner1}` : `${DriveBannerMobile}`} alt='Zunu mail Header' />
                </div>
                {device !== DEVICE.MOBILE &&
                    <div>
                        {/* <PurpleEclipse className={styles.purpleEclipse} /> */}
                        {/* <BlueEclipse className={styles.blueEclipse} /> */}
                    </div>
                }
            </div>

            <div className={styles.mailContentContainer}>
                <div className={styles.downloadContainer}>
                    <div className={styles.downloadLeftContainer}>
                        <p className={styles.downloadHeader}>Keep Files Private, All the Time, Everywhere. Download Zunu Drive Now.</p>
                        {device !== DEVICE.MOBILE &&
                            <div className={styles.download_Os_container}>
                                <div className={styles.downloadBtnContainer}>
                                    <button className={styles.downloadBtn} onClick={() => { handleBuildDownload(os) }}>Download Now</button>
                                </div>

                                <div className={styles.osLogoContainer}>
                                    <Link to="" onClick={() => { handleBuildDownload(PLATFORM.WIN) }}><WindowsLogo className={styles.osLogos} /></Link>
                                    <Link to="" onClick={() => { handleBuildDownload(PLATFORM.MAC) }}><MacLogo className={styles.osLogos} /></Link>
                                    <Link to="" onClick={() => { handleBuildDownload(PLATFORM.LINUX22) }}><LinuxLogo className={styles.osLogos} /></Link>
                                    <Link to="" onClick={() => { handleBuildDownload(PLATFORM.IOS) }}><AppStoreLogo className={styles.osLogos} /></Link>
                                    <Link to="" onClick={() => { handleBuildDownload(PLATFORM.AND) }}><PlayStoreLogo className={styles.osLogos} /></Link>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={styles.downloadImgContainer}>
                        <img src={device == DEVICE.MOBILE ? `${IphoneMobile}` : `${IphoneDesktop}`} />
                    </div>

                    {device == DEVICE.MOBILE &&
                        <div className={styles.osLogoContainer}>
                            <Link to="" onClick={() => { handleBuildDownload(PLATFORM.WIN) }}><WindowsLogo className={styles.osLogos} /></Link>
                            <Link to="" onClick={() => { handleBuildDownload(PLATFORM.MAC) }}><MacLogo className={styles.osLogos} /></Link>
                            <Link to="" onClick={() => { handleBuildDownload(PLATFORM.LINUX22) }}><LinuxLogo className={styles.osLogos} /></Link>
                            <Link to="" onClick={() => { handleBuildDownload(PLATFORM.IOS) }}><AppStoreLogo className={styles.osLogos} /></Link>
                            <Link to="" onClick={() => { handleBuildDownload(PLATFORM.AND) }}><PlayStoreLogo className={styles.osLogos} /></Link>
                        </div>
                    }
                </div>

            </div>

            <div className={`${styles.mailFeatures_Container}  ${styles.mailContentContainer}`}>
                <div className={styles.mailFeatures_header}>
                    Complete, Consistent, and<br></br> Continuous Privacy for Files with Zunu Drive.
                </div>
                <div className={styles.mail_Features_content}>
                    <div className={styles.featureContainer}>
                        <div className={styles.mail_feature}>
                            <DriveFeature1 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Files Protection during Device Theft
                            </p>
                        </div>
                        <div className={styles.mail_feature}>
                            <DriveFeature2 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Files on the Cloud are Invisible to the Cloud
                            </p>
                        </div>
                        <div className={styles.mail_feature}>
                            <DriveFeature3 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Files Secure Even if the Cloud is Breached
                            </p>
                        </div>
                        <div className={styles.mail_feature}>
                            <DriveFeature4 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Privatize Your Existing Clouds—No Migration Needed
                            </p>
                        </div>
                        <div className={styles.mail_feature}>
                            <DriveFeature5 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Unified Access to all Clouds.
                            </p>
                        </div>
                    </div>
                    <div className={styles.featureContainer}>
                        <div className={styles.mail_feature}>
                            <DriveFeature6 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Share Files with Control
                            </p>
                        </div>
                        <div className={styles.mail_feature}>
                            <DriveFeature7 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Encrypted Sync for Seamless Privacy
                            </p>
                        </div>
                        <div className={styles.mail_feature}>
                            <DriveFeature8 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Instant Passwordless Sign-in Across All Devices
                            </p>
                        </div>
                        <div className={styles.mail_feature}>
                            <DriveFeature9 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Dark Web-Proof File Privacy
                            </p>
                        </div>
                        <div className={styles.mail_feature}>
                            <DriveFeature10 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Files Protected from 3rd Party Apps with Storage Access
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.simplifiedPrivacyContainer} ${styles.mailContentContainer}`}>
                <div className={styles.simplifiedPrivacyChild}>
                    <div className={styles.simplifiedHeader}>
                        <div className={styles.mailFeatures_header}>
                            Unify Your Clouds:
                        </div>
                        <div className={`${styles.mailFeatures_header} ${styles.mailFeature1Header}`}>Manage all of them in One Place.</div>
                    </div>
                    <div className={styles.privacyContainer1}>
                        <div className={styles.privacyContent}>
                            <img src={Drive_Ilustration1_1} alt='add Account' />
                        </div>
                        <div className={styles.privacyContent}>
                            <img src={Drive_Ilustration1_2} alt='add Account' />
                        </div>

                        <div className={styles.privacyContent}>
                            <img src={Drive_Ilustration1_3} alt='mail' />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.illustrationContainer} ${styles.mailContentContainer}`}>
                <div className={styles.illustrationText}>
                    <p className={styles.illustrationHeader}>Encrypted Sync: Files Hidden From Cloud, in Cloud.</p>
                </div>
                <div className={styles.illustrationImage}>
                    <img src={Drive_Ilustration1} alt='mail illustration' />
                </div>
            </div>
            <div className={`${styles.mailPrivacyContainer} ${styles.mailContentContainer}`}>
                <div className={styles.mailFeatures_header}>
                    Share with more control.
                </div>
                <div className={styles.ilustrationImgContainer}>
                    <div className={styles.illustrationImg}>
                        <img src={Drive_Ilustration2} alt='illustration' />
                    </div>
                    <div className={styles.illustrationImg1}>
                        <img src={Drive_Ilustration3} alt='illustration' />
                    </div>
                </div>
            </div>

            <div className={`${styles.mailPrivacyContainer} ${styles.mailContentContainer}`}>
                <div className={styles.mailFeatures_header}>
                    One Click Recovery.
                </div>
                <div className={styles.ilustrationImgContainer}>
                    <div className={styles.illustrationImg4_1}>
                        <img src={Drive_Ilustration4} alt='illustration' />
                    </div>
                    <div className={styles.illustrationImg2_container}>
                        <div className={styles.illustrationImg4_2}>
                            <img src={device == DEVICE.MOBILE ? `${Drive_Ilustration4_2_Mobile}` : `${Drive_Ilustration4_2}`} alt='illustration' />
                        </div>
                        <div className={styles.illustrationImg4_2}>
                            <img src={Drive_Ilustration4_3} alt='illustration' />
                        </div>

                    </div>

                </div>
            </div>

            <div className={`${styles.mailPrivacyContainer} ${styles.mailContentContainer}`}>
                <div className={styles.mailFeatures_header}>
                    Full Privacy for Files From Third-Party Apps on Your Device.
                </div>
                <div className={styles.ilustration4img}>
                    <img src={Drive_Ilustration5} alt='illustration' />
                </div>
            </div>
            <div className={`${styles.setUsApartContainer} ${styles.mailContentContainer}`}>
                <div className={styles.setApartHeader}>
                    <div className={styles.mailFeatures_header}>
                        What sets us Apart?
                    </div>
                    <div className={styles.mailFeatureSubHeader}>Our ultimate robust Device-to-Device End-to-End Encryption for 100% Privacy.</div>

                </div>
                <div className={styles.setApartImg}>
                    <img src={device == DEVICE.MOBILE ? `${SetsApart_mobile}` : `${SetsApart_desktop}`} alt='illustration' />
                </div>
            </div>

            <div className={styles.footerContainer}>
                <div className={styles.footerLeftContainer}>
                    <p className={styles.footerText_header}>Keep Files Private, All the Time, Everywhere. Download Zunu Drive Now.</p>
                    {device !== DEVICE.MOBILE &&
                        <div className={styles.footerDownload_container}>

                            <div className={styles.footerBtn_container}>
                                <button className={styles.FooterDownloadBtn} onClick={() => { handleBuildDownload(os) }}>Download Now</button>
                            </div>

                            <div className={styles.footerLogoContainer}>
                                <Link to="" onClick={() => { handleBuildDownload(PLATFORM.WIN) }}><WindowsLogo className={styles.osLogos} /></Link>
                                <Link to="" onClick={() => { handleBuildDownload(PLATFORM.MAC) }}><MacLogo className={styles.osLogos} /></Link>
                                <Link to="" onClick={() => { handleBuildDownload(PLATFORM.LINUX22) }}><LinuxLogo className={styles.osLogos} /></Link>
                                <Link to="" onClick={() => { handleBuildDownload(PLATFORM.IOS) }}><AppStoreLogo className={styles.osLogos} /></Link>
                                <Link to="" onClick={() => { handleBuildDownload(PLATFORM.AND) }}><PlayStoreLogo className={styles.osLogos} /></Link>
                            </div>

                        </div>
                    }
                </div>
                <div className={styles.footerImgContainer}>
                    <img src={device == DEVICE.MOBILE ? `${MacBook_mobile}` : `${Macbook_desktop}`} alt='pixel mobile' />

                </div>
                {device == DEVICE.MOBILE &&
                    <div className={styles.footerLogoContainer}>
                        <Link to="" onClick={() => { handleBuildDownload(PLATFORM.WIN) }}><WindowsLogo className={styles.osLogos} /></Link>
                        <Link to="" onClick={() => { handleBuildDownload(PLATFORM.MAC) }}><MacLogo className={styles.osLogos} /></Link>
                        <Link to="" onClick={() => { handleBuildDownload(PLATFORM.LINUX22) }}><LinuxLogo className={styles.osLogos} /></Link>
                        <Link to="" onClick={() => { handleBuildDownload(PLATFORM.IOS) }}><AppStoreLogo className={styles.osLogos} /></Link>
                        <Link to="" onClick={() => { handleBuildDownload(PLATFORM.AND) }}><PlayStoreLogo className={styles.osLogos} /></Link>
                    </div>
                }
            </div>

        </div>
    )
}

export { Home }