export enum CONSTANTS {


}
export enum DEVICE {
  MOBILE = "mobile",
  TABLET = "tablet",
  DESKTOP = "desktop",
}

export enum PLATFORM {
  WIN = "windows",
  MAC = "mac",
  MACIntel = "mac_intel",
  AND = "android",
  INDUS = "indus",
  IOS = "iOS",
  LINUX = "linux",
  LINUX18 = "linux_18",
  LINUX22 = "linux_22",
  WIN_PORATBLE = "windows_portable",
}

export enum THEME {
  LIGHT = "light",
  DARK = "dark",
}