import { create } from 'zustand';

interface DeviceStore{
    device: string,
    setDevice: (os:string) => void
}

export const useDeviceStore = create<DeviceStore>()((set) => ({
    device: '',
    setDevice: (device) => set({device}),
  }))