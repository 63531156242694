import {
	createBrowserRouter,
	RouterProvider,
	createHashRouter
} from 'react-router-dom';
import Layout from './Layout/layout';
import { Home } from './Pages/Home/home';

const Routing = (props: any) => {
	const AppRouter = window.process ? createHashRouter : createBrowserRouter;

	const routes = AppRouter([
		{
			path: `/`,
			element: <Layout> </Layout>,

			children: [
				{
					path: `/`,
					element: <Home />
				},
				
			]
		}
	]);

	return <RouterProvider router={routes} />;
};

export default Routing;
