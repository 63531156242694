import React, { FC, useEffect, useState } from "react";
import styles from './layout.module.scss'
// import Navbar from "../_shared/components/Navbar/navbar.component";
// import { Footer } from "../_shared/components/Footer/footer.component";
import { Outlet, useLocation } from "react-router-dom";
import {Navbar} from "../_shared/components/Navbar/navbar.component";
import {Footer} from "../_shared/components/Footer/footer.component";


const Layout: FC<{ children: React.ReactNode }> = ({ children }) => {


    return (
        <div className={`${styles.layoutContainer}`}>
            <Navbar />


            <div className={`${styles.main}`}>
                <Outlet />
            </div>

            {/* <Footer /> */}
        </div>
    );
};

export default Layout;
