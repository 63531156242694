import React from 'react'
import styles from './navbar.module.scss'
import { Link } from 'react-router-dom'
import { ReactComponent as Zunu_drive_logo} from '../../../assets/svg/zunu_drive_logo.svg'
import { useDownloadHook } from '../../hooks/download.hook'
import useNavbarHook from './navbar.hook'

function Navbar() {

    const {
        handleBuildDownload, }
        = useDownloadHook()

    const {
        os
    } = useNavbarHook()

    return (
        <nav className={styles.navbar}>
            
            <div className={styles.drive_logoContainer}>
                <Link to="">
                    <Zunu_drive_logo className={styles.drive_Logo} />
                </Link>
            </div>
            <div className={styles.downloadBttnContainer}>
                <Link to="">
                    <button onClick={() => handleBuildDownload(os)} className={styles.downloadBttn}>Download</button>
                </Link>
            </div>
        
        </nav>
    )
}

export { Navbar }